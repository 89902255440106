import React from 'react';
import {Routes, Route, Link, BrowserRouter, Navigate} from "react-router-dom";

import './scss/index.scss';
import {Home} from "./js/Pages/Home";
import {ErrorBoundary} from "./js/ErrorBoundary";
import {ScrollToTop} from "./js/modules/ScrollToTop/ScrollToTop";
import {Header} from "./js/modules/Header/Header";
import {Vote} from "./js/Pages/Vote";
import {Footer} from "./js/modules/Footer/Footer";
import {Winners} from "./js/Pages/Winners";

/*import {inspect} from "@xstate/inspect";

inspect({
    iframe: false
});*/

function App() {

    return (
        <ErrorBoundary>
            <BrowserRouter>
                <ScrollToTop/>
                <Header/>
                <main>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/winners" element={<Winners/>}/>
                        <Route path="/vote/:slab" element={<Vote/>}/>
                        <Route path="/vote/" element={<Navigate to={"/vote/Msk"}/>}/>
                        <Route
                            path="*"
                            element={
                                <main style={{padding: "1rem 0"}}>
                                    <div className="wrapper">
                                        <p>Такой страницы не существует</p>
                                        <Link to="/">На главную</Link>
                                    </div>
                                </main>
                            }
                        />
                    </Routes>
                </main>
            </BrowserRouter>
            <Footer/>
        </ErrorBoundary>
    );
}

export default App;