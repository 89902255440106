import React from "react";
import styles from "./Benefit.module.scss";
import belt from "../../../../images/belt.png";
import beltM from "../../../../images/belt-m.png";

export function Benefit() {
    return (<section className={styles.benefit}>
        <div className="wrapper">
            <div className={styles.flexContainer}>
                <div className={styles.textContainer}>
                    <h2>Улучшение клиентского опыта</h2>
                    <p>17 декабря сотрудники получают во всех точках контакта максимальное знание о клиентском опыте и придумывают новое еще нереализованное эффективное решение на заданный ниже
                       вопрос.</p>
                    <p className={"text-bold"}>
                        Вопрос участникам: <br/>
                        Какие новые фишки телеком оператора стоит внедрить для стимулирования роста абонентской базы, а также лояльности существующего абонента?
                    </p>
                    <p>Разместите свои идеи на портале «Walk The Talk» через приложение.</p>

                    <a
                        href="http://onelink.to/sbwrzq"
                        target={"_blank"}
                        rel={"noopener noreferrer"}
                        className={"btn btnPrimary"}
                    >
                        Скачать приложение
                    </a>
                </div>
                <div className={styles.imageContainer}>
                    <picture>
                        <source srcSet={belt} media={"(min-width: 1152px)"}/>
                        <img src={beltM} alt="Шарик в виде сердца"/>
                    </picture>
                </div>
            </div>
        </div>
    </section>)
}