import React, {useState} from "react";
import styles from "./RegionSelect.module.scss";
import clsx from "clsx";
import {NavLink, useLocation} from "react-router-dom";


//TODO focus on element matching the location

export function RegionSelect(props) {
    let location = useLocation();
    let anchor = location.pathname.slice(6);

    return (
        <section className={styles.regions}>
            <div className="scrollable">

                {
                    props.regionsList.map(
                        (item, index) => {
                            return (
                                <NavLink
                                    to={`/vote/${item.hash}`}
                                    key={`reg${index}`}
                                    className={
                                        clsx(styles.regionLink,
                                            {[styles.active]: anchor === item.hash}
                                        )}
                                >
                                    {item.name}
                                </NavLink>
                            )
                        }
                    )
                }
            </div>
        </section>
    )
}