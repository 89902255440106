import React from "react";
import ReactPlayer from "react-player";
import styles from "./Retro.module.scss";

import preview from "../../../../images/video-preview.jpg";
import {ReactComponent as Play} from "../../../../images/play.svg";

export function Retro() {
    return (<section className={styles.retro}>
        <div className="wrapper">
            <h2>Power Day прошлых лет</h2>
            <p>В 2021 году мы празднуем 10-летие со дня запуска Power Day Tele2. Ежегодно это событие становится ключевым для компании и имеет свою важную историю в формировании лояльности со своими
               настоящими и будущими клиентами.</p>

            <div className={styles.videoContainer}>
                <ReactPlayer
                    className={styles.player}
                    url={"https://tele2-powerday.pro-new.com/files/SummaryPD.mp4"}
                    controls={true}
                    light={preview}
                    playIcon={<button className={styles.playBtn}><Play/></button>}
                    width={"100%"}
                    height={"100%"}
                />
            </div>
        </div>
    </section>)
}