import React from "react";
import {KeyVisual} from "../modules/MainPage/KeyVisual/KeyVisual";
import {Powerday} from "../modules/MainPage/Powerday/Powerday";
import {Broadcast} from "../modules/MainPage/Broadcast/Broadcast";
import {Contest} from "../modules/MainPage/Contest/Contest";
import {Voting} from "../modules/MainPage/Voting/Voting";
import {Benefit} from "../modules/MainPage/Benefit/Benefit";
import {Retro} from "../modules/MainPage/Retro/Retro";

export function Home(props) {
    return (<>
            <KeyVisual/>
            <Powerday/>
            <Broadcast/>
            <Contest/>
            <Voting/>
            <Benefit/>
            <Retro/>
    </>)
}
