import {assign, createMachine} from "xstate";

export const regionMachine = createMachine({
    id: "regionMachine",
    initial: "loading",
    context: {
        currReg: "PDMsk",
        regData: [],
    },
    on: {
        "relocation": {
            target: ".loading",
            actions: ["newLocation"]
        },
    },
    states: {
        "loading": {
            invoke: {
                id: "getData",
                src: "getData",

            },
            on: {
                "done": [
                    {
                        cond: "hasToken",
                        target: "voted",
                        actions: [
                            "saveData"
                        ]

                    },
                    {
                        target: "notVoted",
                        actions: [
                            "saveData"
                        ]

                    }],
            }
        },
        "notVoted": {
            on: {
                "vote": {
                    target: "sending"
                }
            }
        },
        "voted": {},
        "sending": {
            invoke: {
                id: "sendVote",
                src: "sendVote",
            },
            on: {
                "done": {
                    target: "sent"
                }
            }
        },
        "sent": {
            on: {
                "ack": { //acknowledged
                    target: "voted"
                }
            }
        }
    }
}, {
    actions: {
        "saveData": assign({
            "regData": (ctx, message) => message.data
        }),
        "newLocation": assign({
            "currReg": (ctx, message)=> message.data
        })
    },
    services: {
        "getData": (ctx) => {
            return function (send) {
                let regToken = localStorage.getItem(ctx.currReg);

                fetch(`/api/region/${ctx.currReg.toLowerCase()}`,{
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                    },
                })
                    .then(response => response.json())
                    .then(response => {
                        send({
                            type: "done",
                            data: response,
                            token: !!regToken
                        })
                    });
            }
        },
        "sendVote": (ctx, message) => {
            return function (send) {
                fetch("/api/vote", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        "region": ctx.currReg,
                        "id": message.id
                    })
                })
                    .then(response => response.json())
                    .then(response => {
                        if (response.code === 200) {
                            localStorage.setItem(ctx.currReg, "true");
                            send({
                                type: "done"
                            })
                        }
                    })
            }
        },

    },
    guards: {
        "hasToken": (ctx, message) => {
            return message.token
        }
    }
});