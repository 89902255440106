import React, {useEffect, useState} from "react";
import styles from "./Header.module.scss";
import {ReactComponent as Logo} from "../../../images/icons/logo.svg";
import clsx from "clsx";
import {Link, NavLink} from "react-router-dom";
import {Dialog, DialogDisclosure, useDialogState, VisuallyHidden} from "reakit";

export function Header() {
    const mobMenu = useDialogState();
    let [windowSize, setWindowSize] = useState("mobile");

    useEffect(() => {
        let viewportSize = window.matchMedia("(max-width: 767px)");

        function setState() {
            setWindowSize(viewportSize.matches ? "mobile" : "desktop")
        }

        setState();

        viewportSize.addEventListener("change", setState);
        return function () {
            viewportSize.removeEventListener("change", setState);
        }
    }, []);

    return (
        <header className={styles.header} role={"banner"}>
            <div className={clsx("wrapper", styles.wrapper)}>
                <Link to={"/"}><Logo className={styles.logo}/></Link>
                <nav className={styles.menu}>
                    {
                        windowSize === "mobile"
                            ? <>
                                <DialogDisclosure
                                    className={styles.burger}
                                    {...mobMenu}
                                >
                                    <VisuallyHidden>
                                        Мобильное меню
                                    </VisuallyHidden>
                                </DialogDisclosure>
                                <Dialog
                                    {...mobMenu}
                                    className={styles.mobileMenu}
                                >
                                    <NavLink to={"/"} onClick={() => mobMenu.hide()}>
                                        Главная
                                    </NavLink>
                                    <NavLink to={"/vote"} onClick={() => mobMenu.hide()}>
                                        Галерея
                                    </NavLink>
                                    <NavLink to={"/winners"} onClick={() => mobMenu.hide()}>
                                        Победители
                                    </NavLink>
                                </Dialog>
                            </>
                            : <div className={styles.links}>
                                <NavLink to={"/"}>Главная</NavLink>
                                <NavLink to={"/vote"}>Галерея</NavLink>
                                <NavLink to={"/winners"}>Победители</NavLink>
                            </div>
                    }
                </nav>
            </div>
        </header>
    )
}
