import React, {useEffect, useState} from "react";
import styles from "./Timer.module.scss";

import {calcTimeLeft} from "../../calcTimeLeft";

export function Timer(props) {
    /*  Формат даты end: "2021-12-17T18:00:00" */
    let [timeLeft, setTimeLeft] = useState(calcTimeLeft(props.end));

    useEffect(() => {
            const timer = setTimeout(() => {
                setTimeLeft(calcTimeLeft(props.end));
            }, 1000);

            return () => clearTimeout(timer);
        });

    return (
        <article className={styles.timer}>
            <span>{timeLeft.days}</span>дн.&nbsp;
            <span>{
                String(timeLeft.hours).padStart(2, "0")
            }</span>ч.&nbsp;
            <span>{
                String(timeLeft.minutes).padStart(2, "0")
            }</span>мин.&nbsp;
            <span>{
                String(timeLeft.seconds).padStart(2, "0")
            }</span>сек.
        </article>
    )
}