import React from "react";
import styles from "./Contest.module.scss";
import clsx from "clsx";

import camera from "../../../../images/camera.jpg";
import instagram from "../../../../images/ig.jpg";
import gift from "../../../../images/gift.jpg";
import {TagsList} from "./TagsList";


export function Contest() {
    return (
        <article className={clsx(styles.contest)}>
            <div className="wrapper">
                <h2>
                    Конкурс &laquo;Сам&nbsp;себе&nbsp;Санта&raquo;
                </h2>

                <div className={styles.cards}>
                    <article className={styles.card}>
                        <div className={styles.cardImage}>
                            <img src={camera} alt="Фотоаппарат"/>
                        </div>
                        <div className={styles.cardText}>
                            <h3 className={"h4 text-sans"}>
                                Сделай
                            </h3>
                            <p>фотографию, где ты&nbsp;демонстрируешь себя в&nbsp;роли Санты
                            </p>
                        </div>
                    </article>
                    <article className={styles.card}>
                        <div className={styles.cardImage}>
                            <img src={instagram} alt="Иконка Инстаграм"/>
                        </div>
                        <div className={styles.cardText}>
                            <h3 className={"h4 text-sans"}>
                                Размести
                            </h3>
                            <p>в&nbsp;Instagram с&nbsp;#хэштегом своего Макрорегиона</p>
                        </div>
                    </article>
                    <article className={styles.card}>
                        <div className={styles.cardImage}>
                            <img src={gift} alt="Коробка с подарком"/>
                        </div>
                        <div className={styles.cardText}>
                            <h3 className={"h4 text-sans"}>
                                Лучшие работы в&nbsp;каждом&nbsp;МР
                            </h3>
                            <p>
                                по итогам голосования получат приз
                            </p>
                        </div>
                    </article>
                </div>

                <TagsList/>
            </div>
        </article>
    )
}