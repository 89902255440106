import React from "react";
import styles from "./KeyVisual.module.scss";
import clsx from "clsx";

export function KeyVisual() {
    return (
        <article className={clsx(styles.hero)}>
            <div className="wrapper">
                <h1 className={"stencil"}>
                    Пришло <br/>
                    наше время
                </h1>
                <p className={"h3 text-serif"}>Стать Сантой</p>
            </div>
        </article>
    )
}
