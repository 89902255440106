import React from "react";
import styles from "./Powerday.module.scss";

import balloon from "../../../../images/balloon.png";
import balloonM from "../../../../images/balloon-m.png";
import clsx from "clsx";
import {useInView} from "react-intersection-observer";

export function Powerday() {
    /* Intersection observer for starting animation */
    const {ref, inView, entry} = useInView({
        threshold: 0.5
    });

    return (<section className={styles.powerday}>
        <div className={"wrapper"}>
            <div className={styles.flexContainer}>
                <div className={styles.textContainer}>
                    <h2 className={"h1"}>
                        Power Day #15
                    </h2>
                    <p>День, когда сотрудники Tele2 выходят на связь с&nbsp;любимыми клиентами, активно слушают их и&nbsp;получают обратную связь.</p>
                    <p>Дата проведения: 17.12.21</p>
                </div>
                <div ref={ref} className={styles.imageContainer}>
                    <div
                        className={
                            clsx(
                                styles.bg,
                                "wow",
                                {["animated zoomIn"]: inView},
                            )}
                        style={{"visibility": inView ? "visible" : "hidden"}}
                    />
                    <picture
                        className={
                            clsx(
                                "wow",
                                {["animated bounceInRight"]: inView},
                            )
                        }
                        style={{"visibility": inView ? "visible" : "hidden"}}
                    >
                        <source srcSet={balloon} media={"(min-width: 1152px)"}/>
                        <img src={balloonM} alt="Шарик в виде сердца"/>
                    </picture>
                </div>
            </div>
        </div>
    </section>)
}