import React from "react";
import styles from "./Broadcast.module.scss";
import clsx from "clsx";

export function Broadcast() {
    return (
        <section className={clsx(styles.broadcast, "darkBg")}>
            <div className="wrapper">
                <h2>HAPPY NEW AIR</h2>
                <div className={styles.flexContainer}>
                    <div className={styles.iframeContainer}>
                        <iframe title={"Эфир Tele2"} tabIndex={0} src="https://facecast.net/v/sv8ppo" style={{"border": "none", "outline": "0 !important"}} allow="fullscreen" allowFullScreen/>
                    </div>
                    <div className={styles.textContainer}>
                        <p>Праздничный радиоэфир Tele2 LIVE с 6:00 до 16:00 по московскому времени</p>

                        <p>Ведущие: <br/>
                           С 06:00 до 9:00 – сотрудники Tele2 Никита Лепехин и Анастасия Бочкова <br/>
                           С 9:00 до 12:00 – сотрудники Tele2 Никита Лепехин и Александра Никольская <br/>
                           С 12:00 до 16:00 – сотрудники Tele2 Дина Семенова и Василий Корнеев, а также специальный гость эфира – радиоведущий Антон Комолов</p>
                        <p>В прямом эфире вас ждет:</p>
                        <ul>
                            <li>поздравления от топ-менеджмента и макрорегиональных директоров,</li>
                            <li>прямое включение из дистанционного центра в Москве,</li>
                            <li>10 часов новогоднего настроения, поздравлений, общения в прямом эфире, конкурсов и много хорошей музыки</li>
                        </ul>
                        <p>До встречи в эфире!</p>
                    </div>
                </div>
            </div>
        </section>)
}