import {useEffect, useState} from "react";
import styles from "../modules/MainPage/Benefit/Benefit.module.scss";
import gallery from "../modules/VotePage/Gallery/Gallery.module.scss";
import card from "../modules/VotePage/Card/Card.module.scss";

export function Winners() {

    let [winners, setWinners] = useState([]);

    useEffect(() => {
        fetch("/api/winners", {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        })
            .then(response => response.json())
            .then(response => setWinners(response));
    }, []);

    return (
        <section className={styles.benefit}>
            <div className="wrapper">
                <h1>Победители</h1>
                {
                    winners.length>0 && <p>Победители выбраны с учетом количества набранных голосов в каждом МР.</p>
                }
                <div className={gallery.gallery}>
                    {   winners.length>0
                        ? winners.map((item, index) => {
                            return <article className={card.card} key={`card${index}`}>
                                <div className={card.image}>
                                    <img src={item.image} alt=""/>
                                </div>
                                <div>
                                    {`@${item.username}`}
                                </div>
                                <div style={{"fontSize": "0.9rem", "marginTop": "0.5rem"}}>
                                    {item.region}
                                </div>
                            </article>
                        })

                        : "Победители еще не определены"
                    }
                </div>
            </div>
        </section>
    )
}