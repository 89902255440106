export function periodTimer (from, to) {
    /*  Формат даты end: "2021-12-17T18:00:00" */
    let now = +new Date(),
        start = +Date.parse(from),
        end = +Date.parse(to);

    if (start > now || now >= end) {
        console.log("The time is not right");
        return false;
    } else {
        console.log("Go ahead")
        return true;
    }
}