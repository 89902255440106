import React from "react";
import styles from "./Footer.module.scss";
import clsx from "clsx";

export function Footer() {
    return (<footer className={clsx("darkBg", styles.footer)}>
        <div className={"wrapper"}>
                <div className={"text-center"}>
                    &copy; Tele2&nbsp;Россия, 2021
                </div>
        </div>
    </footer>)
}