import styles from "./Card.module.scss";
import {useEffect, useState} from "react";
import {periodTimer} from "../../Timer/PeriodTimer";

export function Card(props) {
    let [votingIsOpen, setVotingIsOpen] = useState(undefined);
    let isThisTime = periodTimer("2021-12-17T18:00:00", "2021-12-22T18:00:00");

    useEffect(() => {
        setVotingIsOpen(isThisTime);
    });

    return (
        <article className={styles.card}>
            <div className={styles.image}>
                <img src={props.card.image} alt=""/>
            </div>
            <div className={styles.info}>
                <span className={styles.user}>
                    {`@${props.card.username}`}
                </span>
                {
                    votingIsOpen &&
                    <>

                        {
                            !props.voted &&
                            <button
                                className={"btn btnPrimary btnSmall"}
                                onClick={() => props.sendVote(props.card.id)}
                            >
                                Отдать голос
                            </button>
                        }

                    </>
                }
            </div>
        </article>
    )
}