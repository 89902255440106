import React, {useEffect} from "react";
import "../modules/ServerMock/server";
import {RegionSelect} from "../modules/VotePage/RegionSelect/RegionSelect";

import {Loader} from "../modules/Loader";
import {Gallery} from "../modules/VotePage/Gallery/Gallery";
import {useLocation} from "react-router-dom";
import {Dialog, DialogBackdrop, useDialogState, VisuallyHidden} from "reakit";
import {useMachine} from "@xstate/react";
import {regionMachine} from "../xstate/region.machine";

import styles from "../modules/modals/Modal.module.scss";

/*
 * - При открытии страницы фетч данных для региона по умолчанию (Москва).
 * - Проверяем, есть ли в localStorage файлик с данными о том, был ли отдан голос за регион. Кнопки Отдать голос не показываются, если да.
 * - По клику на выбор региона фетчится выбранный. Нельзя выбрать другой, пока не загрузится (лоадер на всю страницу).
 * - По клику на "Отдать голос" кладем в localStorage значение выбранного региона. И скрываем для этого региона кнопки.
 * - Нажатие на выбор региона добавляет в адрес якорную ссылку на него, соответственно, если человек пришел по прямой ссылке на регион, то показывается сразу он
 */

let regionsList = [
    {
        "id": 1,
        "name": "Москва",
        "hash": "Msk"
    },
    {
        "id": 2,
        "name": "Северо-Запад",
        "hash": "SZ"
    },
    {
        "id": 3,
        "name": "Юг",
        "hash": "Ug"
    },
    {
        "id": 4,
        "name": "Центр",
        "hash": "Centr"
    },
    {
        "id": 5,
        "name": "Черноземье",
        "hash": "Black"
    },
    {
        "id": 6,
        "name": "Сибирь",
        "hash": "Sibir"
    },
    {
        "id": 7,
        "name": "Байкал и Дальний Восток",
        "hash": "BDV"
    },
    {
        "id": 8,
        "name": "Урал",
        "hash": "Ural"
    },
    {
        "id": 9,
        "name": "Волга",
        "hash": "Volga"
    }
];

export function Vote() {
    let location = useLocation();
    let dialog = useDialogState({animated: true});
    let selectedReg = location.pathname.slice(6);
    let [state, send] = useMachine(regionMachine, {
        context: {
            currReg: selectedReg,
            regData: []
        },
        devTools: true
    });

    useEffect(() => {
        send({
            type: "relocation",
            data: selectedReg
        })
    }, [selectedReg]);

    return (
        <>
            <RegionSelect
                regionsList={regionsList}
            />
            <section className={"voting"}>
                <div className="wrapper">
                    <h1>Голосование</h1>
                    <p className={"h5 text-sans"}>
                    <span className={"text-bold"}>
                        {
                            regionsList.find(obj => obj.hash === selectedReg)?.name
                        }
                    </span>&emsp;#PD{selectedReg}
                    </p>
                    <p>Голосование продлится с 18:00 17.12.2021 по 18:00 22.12.2021.</p>
                    <p>Проголосовать можно только за одну фотографию от каждого МР.</p>
                    {
                        state.matches("loading")
                            ? <Loader/>
                            : <Gallery
                                voted={state.matches("voted")}
                                sendVote={(id) => send({type: "vote", id: id})}
                                data={state.context.regData}
                            />
                    }
                </div>
            </section>
            <DialogBackdrop
                {...dialog}
                hide={() => {
                    dialog.hide();
                    send("ack");
                }}
                visible={state.matches("sent")}
                className={styles.backdrop}
            >
                <Dialog
                    {...dialog}
                    visible={state.matches("sent")}
                    aria-label="Голос учтен"
                    className={styles.modal}
                >
                    <button
                        onClick={() => {
                            dialog.hide();
                            send("ack");
                        }}
                        className={styles.close}
                    >
                        <VisuallyHidden>
                            Закрыть модальное окно
                        </VisuallyHidden>
                    </button>
                    Твой голос учтен!
                </Dialog>
            </DialogBackdrop>

        </>
    )
}
