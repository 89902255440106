import styles from "./Gallery.module.scss";
import {Card} from "../Card/Card";

export function Gallery(props) {

    return (
        <section className={styles.gallery}>
            {
                props.data.length < 1
                    ? <p>Еще нет ни одной работы.</p>
                    : props.data.map((item, index) => {
                        return (
                            <Card
                                voted={props.voted}
                                sendVote={props.sendVote}
                                card={item}
                                key={`card${index}`}
                            />
                        )
                    })
            }
        </section>
    )
}