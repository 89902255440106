import React from "react";
import styles from "./TagsList.module.scss";
import {Disclosure, DisclosureContent, useDisclosureState} from "reakit";
import clsx from "clsx";

export function TagsList() {
    const dropdown = useDisclosureState();

    let tags = [
        {
            "region": "Волга",
            "tag": "#PDVolga"
        },
        {
            "region": "Москва",
            "tag": "#PDMsk"
        },
        {
            "region": "Урал",
            "tag": "#PDUral"
        },
        {
            "region": "Северо-запад",
            "tag": "#PDSZ"
        },
        {
            "region": "Юг",
            "tag": "#PDUg"
        },
        {
            "region": "Центр",
            "tag": "#PDCentr"
        },
        {
            "region": "БиДВ",
            "tag": "#PDBDV"
        },
        {
            "region": "Черноземье",
            "tag": "#PDBlack"
        },
        {
            "region": "Сибирь",
            "tag": "#PDSibir"
        },

    ]

    return (
        <section className={styles.tagsList}>
            <h3>
                <Disclosure {...dropdown} className={clsx("text-serif", styles.toggle)}>
                    Список хэштегов для макрорегионов
                </Disclosure>
            </h3>
            <DisclosureContent {...dropdown}>
                <table className={styles.tagsTable}>
                    <thead>
                        <tr>
                            <th>МР</th>
                            <th>Хэштег</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            tags.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{item.region}</td>
                                        <td>{item.tag}</td>
                                    </tr>
                                )
                            })

                        }
                    </tbody>
                </table>
            </DisclosureContent>
        </section>
    )
}