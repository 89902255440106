export function calcTimeLeft(end) {
    let now = new Date(),
        diff = +Date.parse(end) - +now,
        timeLeft = {};

    if (diff > 0) {
        return timeLeft = {
            "days": Math.floor(diff / (1000 * 60 * 60 * 24)),
            "hours": Math.floor((diff / (1000 * 60 * 60)) % 24),
            "minutes": Math.floor((diff / 1000 / 60) % 60),
            "seconds": Math.floor((diff / 1000) % 60)
        };
    } else {
        return timeLeft = {"days": 0, "hours": 0, "minutes": 0, "seconds": 0};
    }
}