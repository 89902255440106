import React from "react";
import styles from "./Voting.module.scss";

import clsx from "clsx";
import {Timer} from "../../Timer/Timer";
import collage from "../../../../images/collage.jpg";

import {Link} from "react-router-dom";

export function Voting() {
    return (
        <section className={clsx(styles.voting, "darkBg")}>
            <div className="wrapper">
                <div className={styles.flexContainer}>
                    <div className={styles.textContainer}>
                        <h2>Голосование</h2>
                        <p>Голосуй за лучшие работы участников конкурса!</p>
                        <p>
                            Голосование начнется 17.12.2021 в 18:00 по МСК <br/>
                            До начала голосования:
                        </p>

                        <Timer end={"2021-12-17T18:00:00"}/>

                        <Link to={"/vote"} className={"btn btnPrimary"}>
                            Смотреть работы участников
                        </Link>
                    </div>
                    <div className={styles.imageContainer}>
                        <img src={collage} aria-hidden={"true"} alt=""/>
                    </div>
                </div>
            </div>
        </section>
    )
}